export function removeExcludeKeys (object: object, saveKeys?: Array<string>) {
  if (!saveKeys) {
    return
  }
  for (const key in object) {
    if (saveKeys?.indexOf(key) === -1) {
      delete object[key as keyof typeof object]
    }
  }
}

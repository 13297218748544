export const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export default class WeekdaysModel {
  public monday: boolean
  public tuesday: boolean
  public wednesday: boolean
  public thursday: boolean
  public friday: boolean
  public saturday: boolean
  public sunday: boolean

  constructor (data: any = {}) {
    this.reload(data)
  }

  reload (data?: any) {
    if (!data) {
      return this
    }
    for (const key in WEEKDAYS) {
      (<any> this)[WEEKDAYS[key]] = data[WEEKDAYS[key]] ?? false
    }
  }
}


import { Vue } from 'vue-class-component'
import PerfectScrollbar from 'perfect-scrollbar'
import { toggle } from 'slidetoggle'
import { Watch } from 'vue-property-decorator'
import ChannelModel from '@/models/channel-model'

export default class Sidebar extends Vue {
  sidebar: any = undefined
  currentChannel: undefined|string = undefined

  data () {
    return {
      currentChannel: String,
    }
  }

  async go (link: string) {
    await this.$router.push(link)
    return false
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    if (this.$route.name !== 'channel') {
      return
    }
    this.currentChannel = this.$route.params.id.toString()
  }

  mounted () {
    this.sidebar = this.$el.querySelector('#sidebar')
    this._init()
    this._onResize()
  }

  created () {
    this.$store.dispatch('updateChannels').then((res) => {
      if (this.$route.name !== 'channel') {
        return
      }
      const channel: ChannelModel = this.$store.getters.getChannel(this.currentChannel)
      if (channel) {
        document.title = channel.name + ' - Grandad'
      }
    })
  }

  _onResize () {
    const w = window.innerWidth
    if (w < 1200) {
      this.sidebar.classList.remove('active')
    } else {
      this.sidebar.classList.add('active')
    }

    this._toggleOverflowBody(true)
  }

  _init () {
    this.$el.querySelectorAll('.burger-btn').forEach((el: any) => el.addEventListener('click', this._toggle.bind(this)))
    this.$el.querySelectorAll('.sidebar-hide').forEach((el: any) => el.addEventListener('click', this._toggle.bind(this)))
    window.addEventListener('resize', this._onResize.bind(this))

    const sidebarItems = this.$el.querySelectorAll('.sidebar-item.has-sub')
    for (let i = 0; i < sidebarItems.length; i++) {
      const sidebarItem = sidebarItems[i]

      sidebarItem.querySelector('.sidebar-link')?.addEventListener('click', (e: any) => {
        e.preventDefault()

        const submenu: HTMLElement|null = sidebarItem.querySelector('.submenu')
        if (submenu?.classList.contains('active')) submenu.style.display = 'block'

        if (submenu?.style.display === 'none') submenu.classList.add('active')
        else submenu?.classList.remove('active')
        if (submenu) {
          toggle(submenu, {
            miliseconds: 300
          })
        }
      })
    }

    // // Perfect Scrollbar Init
    // if (typeof PerfectScrollbar === 'function') {
    //   const container = document.querySelector('.sidebar-wrapper')
    //   const ps = new PerfectScrollbar(container || '', {
    //     wheelPropagation: false
    //   })
    // }

    // // Scroll into active sidebar
    // setTimeout(() => document.querySelector('.sidebar-item.active')?.scrollIntoView(false), 100)
  }

  /**
   * Toggle Sidebar
   */
  _toggle () {
    const sidebarState = this.sidebar.classList.contains('active')
    if (sidebarState) {
      this._hide()
    } else {
      this._show()
    }
  }

  /**
   * Show Sidebar
   */
  _show () {
    this.sidebar.classList.add('active')
    this._toggleOverflowBody()
  }

  _hide () {
    this.sidebar.classList.remove('active')
    this._toggleOverflowBody()
  }

  _toggleOverflowBody (active?: any) {
    const sidebarState = this.$el.classList.contains('active')
    const body: HTMLBodyElement | null = document.querySelector('body')
    if (!body) {
      return
    }
    if (typeof active === 'undefined') {
      body.style.overflowY = sidebarState ? 'hidden' : 'auto'
    } else {
      body.style.overflowY = active ? 'auto' : 'hidden'
    }
  }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.itemView), {
        model: item,
        key: item.id,
        ref_for: true,
        ref: "itemsView",
        onDelete: ($event: any) => (_ctx.onDelete(index))
      }, null, 8, ["model", "onDelete"]))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "bi bi-plus-circle d-block icon-button xl",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
      })
    ])
  ]))
}
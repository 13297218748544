import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dfa9ec0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "full",
  class: "d-none"
}
const _hoisted_2 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-title white" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.isActive }, "modal fade"]),
      tabindex: "-1",
      role: "dialog"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(`modal-header bg-${_ctx.theme}`)
          }, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.modalTitle), 1)
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.modalBody), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light-secondary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "Close"),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(`btn btn-${_ctx.theme}`),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveChanges && _ctx.saveChanges(...args)))
            }, "Save changes", 2)
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.isActive }, "modal-backdrop fade"])
    }, null, 2)
  ], 512))
}
import { createToast } from 'mosha-vue-toastify'

export function successToast (message: string, options?: Record<string, unknown>) {
  createToast(
    message,
    {
      ...{
        timeout: 3000,
        type: 'success',
        transition: 'slide',
        showIcon: true
      },
      ...options
    }
  )
}

export function errorToast (message: string, options?: Record<string, unknown>) {
  createToast(
    message,
    {
      ...{
        timeout: 3000,
        type: 'danger',
        transition: 'slide',
        showIcon: true
      },
      ...options
    }
  )
}

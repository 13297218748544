import ChannelModel from '@/models/channel-model'
import HintModel from '@/models/hint-model'
import RuleModel from '@/models/rule-model'
import { errorToast } from '@/helpers/toastify'
import store from '@/store'
import { AppConfig } from '@/config/AppConfig'
import ReminderModel from '@/models/reminder-model'

export class APIClient {
  constructor (private readonly apiUrl: string) {}

  async sendRequest (path: string, data: any = undefined, method = 'GET', auth = true, options = {}): Promise<any> {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Content-Type', 'application/json')

    if (auth && localStorage.getItem('accessToken')) {
      requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
    }

    const init: RequestInit = {
      method: method,
      headers: requestHeaders,
    }

    if (data) {
      init.body = JSON.stringify(data)
    }
    const response = await fetch(this.apiUrl + path, init)

    if (
      auth
      && response.status === 401
      && localStorage.getItem('refreshToken')
      && !Object.prototype.hasOwnProperty.call(options, 'retryAfterLogin')
    ) {
      await this.useRefreshToken(localStorage.getItem('refreshToken'))
      return await this.sendRequest(path, data, method, auth, { retryAfterLogin: true })
    }
    if (response.status === 422) {
      const responseData = await response.clone().json()
      errorToast(responseData['hydra:description'], { timeout: 5000 })
    } else if (response.status === 403) {
      errorToast('You do not have access')
    } else if (response.status >= 400) {
      errorToast('Error')
    }

    return response
  }

  async getChannels (): Promise<ChannelModel[]> {
    const serverResponse = await this.sendRequest('/api/channels')
    const data = await serverResponse.json()
    return convertListResponseToObjectList(data['hydra:member'], ChannelModel)
  }

  async getHintsByChannel (channelId: string): Promise<HintModel[]> {
    const serverResponse = await this.sendRequest('/api/channels/' + channelId + '/hints')
    const data = await serverResponse.json()
    return convertListResponseToObjectList(data['hydra:member'], HintModel, ['rules'])
  }

  async updateHint (hint: HintModel, fields?: Array<string>) {
    hint.rules.forEach((rule: RuleModel) => {
      this.preProcessed(rule)
    })

    const serverResponse = await this.sendRequest('/api/hints/' + hint.id, hint.dataForUpdate(fields), 'PATCH')
    const data = await serverResponse.json()
    if (serverResponse.status !== 200) {
      return undefined
    }
    return convertNodeResponseToObject(data, HintModel, ['rules'])
  }

  async createHint (hint: HintModel): Promise<HintModel|undefined> {
    if (hint.id) {
      throw new Error('The object is already created')
    }

    this.preProcessed(hint)
    hint.rules.forEach((rule: RuleModel) => {
      this.preProcessed(rule)
    })

    const serverResponse = await this.sendRequest('/api/hints', hint.dataForCreate(), 'POST')

    if (serverResponse.status !== 201) {
      return undefined
    }

    const data = await serverResponse.json()

    return convertNodeResponseToObject(data, HintModel, ['rules'])
  }

  async deleteHint (hintId: string) {
    const serverResponse = await this.sendRequest('/api/hints/' + hintId, undefined, 'DELETE')
    return serverResponse
  }

  async getRemindersByChannel (channelId: string): Promise<HintModel[]> {
    const serverResponse = await this.sendRequest('/api/channels/' + channelId + '/reminders')
    const data = await serverResponse.json()
    return convertListResponseToObjectList(data['hydra:member'], ReminderModel)
  }

  async updateReminder (reminder: ReminderModel, fields?: Array<string>): Promise<ReminderModel|undefined> {
    const serverResponse = await this.sendRequest('/api/reminders/' + reminder.id, reminder.dataForUpdate(fields), 'PATCH')
    const data = await serverResponse.json()
    if (serverResponse.status !== 200) {
      return undefined
    }
    return reminder.reload(data)
  }

  async createReminder (reminder: ReminderModel): Promise<ReminderModel|undefined> {
    if (reminder.id) {
      throw new Error('The object is already created')
    }

    this.preProcessed(reminder)

    const serverResponse = await this.sendRequest('/api/reminders', reminder.dataForCreate(), 'POST')

    if (serverResponse.status !== 201) {
      return undefined
    }

    const data = await serverResponse.json()

    return convertNodeResponseToObject(data, ReminderModel)
  }

  async deleteReminder (id: string) {
    const serverResponse = await this.sendRequest('/api/reminders/' + id, undefined, 'DELETE')
    return serverResponse
  }

  async exchangeAuthToken (token: string, userId: string) {
    const serverResponse = await this.sendRequest(
      '/api/login/exchange-auth-token', {
        user: userId,
        token: token,
      },
      'POST'
    )

    return serverResponse
  }

  async useRefreshToken (refreshToken: string | null) {
    const serverResponse = await this.sendRequest(
      '/api/login/refresh-token', {
        refresh_token: refreshToken,
      },
      'POST',
      false
    )
    if (serverResponse.status !== 200) {
      console.log('remove')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      store.commit('setAccessToken', undefined)
      return
    }
    const data = await serverResponse.json()
    localStorage.setItem('refreshToken', data.refresh_token)
    localStorage.setItem('accessToken', data.token)
    store.commit('setAccessToken', data.token)
  }

  preProcessed (entity: any) {
    if (!entity.userId) { // HasUserInterface
      entity.userId = '/api/users/' + store.state.user?.id
    }
  }
}

function convertNodeResponseToObject (item: any, targetClass: any, subResourcesList?: string[]) {
  if (subResourcesList) {
    subResourcesList.forEach((subRes: string) => {
      const subItemList: any[] = []
      item[subRes].forEach((rule: any) => {
        subItemList.push(rule)
      })
      item[subRes] = subItemList
    })
  }
  // eslint-disable-next-line new-cap
  return new targetClass(item)
}

function convertListResponseToObjectList (list: any, targetClass: any, subResourcesList?: string[]) {
  const res: any[] = []
  list.forEach((item: any) => {
    // eslint-disable-next-line new-cap
    res.push(convertNodeResponseToObject(item, targetClass, subResourcesList))
  })

  return res
}

let apiClient: APIClient | undefined

export function getApiClient () {
  if (!apiClient) {
    apiClient = new APIClient(AppConfig.apiUrl)
  }

  return apiClient
}

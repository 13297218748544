
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    modalTitle: String,
    modalBody: String
  },
  emits: ['confirm', 'close']
})
export default class ConfirmModal extends Vue {
  private isActive = false
  private confirmData: any

  data () {
    return {
      isActive: false,
      theme: 'warning'
    }
  }

  show (confirmData?: any) {
    (this.$refs.full as any).classList.remove('d-none')
    this.confirmData = confirmData
    setTimeout(() => {
      this.isActive = true
    }, 50)
  }

  close () {
    this.isActive = false
    this.$emit('close')
    setTimeout(() => {
      (this.$refs.full as any).classList.add('d-none')
    }, 300)
  }

  saveChanges () {
    this.close()
    this.$emit('confirm', this.confirmData)
  }
}

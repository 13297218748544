
import { Options, Vue } from 'vue-class-component'
import { hide } from 'slidetoggle'
import { Component } from 'vue-property-decorator'

@Options({
  props: {
    items: Array,
    itemView: Component,
  }
})
export default class ListOfItems extends Vue {
  private itemView: Vue
  private items: Array<any>

  mounted () {
    this.$el.querySelectorAll('textarea').forEach((item: HTMLElement) => { item.dispatchEvent(new Event('keyup')) })
  }

  onAdd () {
    this.$emit('add')
  }

  delete (index: number) {
    const el = (this.$refs.itemsView as any)[index].$el
    hide(el, {
      miliseconds: 400,
      onAnimationEnd: () =>
        this.items?.splice(index, 1)
    })
  }

  private onDelete (index: number) {
    this.$emit('delete', index)
  }
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChannelView from '@/views/ChannelView.vue'
import SidebarLayout from '@/views/SidebarLayout.vue'
import ExchangeTokenView from '@/views/ExchangeTokenView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login/exchange-auth-token',
    name: 'auth-exchange-token',
    component: ExchangeTokenView,
    meta: { title: 'Login - Grandad' }
  },
  {
    path: '/',
    name: 'home',
    component: SidebarLayout,
    children: [
      {
        path: '',
        component: HomeView,
      },
      {
        path: '/channel/:id',
        name: 'channel',
        component: ChannelView
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

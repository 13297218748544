import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'mosha-vue-toastify/dist/style.css'
import '@/assets/css/bootstrap.css'
import '@/assets/css/app.css'

const app = createApp(App)
app.use(store)
app.use(router)
// app.use(BootstrapVue3)
app.mount('#app')

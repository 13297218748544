
import { Options, Vue } from 'vue-class-component'

let _uuid = 0
@Options({
  props: {
    modelValue: false,
    value: undefined,
    name: undefined
  },
})
export default class CircleCheckbox extends Vue {
  private isChecked: boolean
  private modelValue: boolean
  private key: string

  data () {
    return {
      key: String,
    }
  }

  created () {
    this.isChecked = this.modelValue
  }

  toggleCheck () {
    this.isChecked = !this.isChecked
    this.$emit('update:modelValue', this.isChecked)
  }

  beforeCreate () {
    this.key = 'cch' + _uuid
    _uuid++
  }
}

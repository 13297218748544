
import { Vue } from 'vue-class-component'
import { getApiClient } from '@/api/APIClient'
import store from '@/store'

export default class ExchangeTokenView extends Vue {
  private token: string | undefined
  private userId: string | undefined
  private isShowError: boolean
  data () {
    return {
      token: String,
      userEmail: String,
      isShowError: String
    }
  }

  async created () {
    this.token = this.$route.query.token?.toString()
    this.userId = this.$route.query.user?.toString()

    this.isShowError = false

    if (!this.token || !this.userId) {
      this.isShowError = true
      return
    }
    const serverResponse = await getApiClient().exchangeAuthToken(this.token, this.userId)
    if (serverResponse.status !== 200) {
      this.isShowError = true
      return
    }
    const data = await serverResponse.json()
    localStorage.setItem('accessToken', data.token)
    localStorage.setItem('refreshToken', data.refresh_token)
    store.commit('setAccessToken', data.token)

    const channel = this.$route.query.channel?.toString()
    await this.$router.replace('/channel/' + channel)
  }
}

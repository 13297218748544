export default class ChannelModel {
  public id: string
  public name: string
  public externalId: string
  public provider: string
  private _id: any

  constructor (data?: any) {
    this._id = data?.id
    this.id = data?.id.split('/').at(-1)
    this.name = data?.name
    this.externalId = data?.externalId
    this.provider = data?.provider
  }
}

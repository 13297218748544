import { ApiEntityInterface } from '@/models/interface/api-entity-interface'
import WeekdaysModel from '@/models/weekdays-model'
import { removeExcludeKeys } from '@/helpers/object-helper'

export enum ReminderFrequency {
  Once = 'once',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export default class ReminderModel implements ApiEntityInterface {
  public id: string
  public message: string
  public channelId: string
  public frequency: ReminderFrequency = ReminderFrequency.Weekly
  public sendingDate: string
  public sendingTime: string
  public sendingDays: Array<number>
  public sendingWeekdays: WeekdaysModel
  public enabled = true

  constructor (data?: any) {
    this.sendingWeekdays = new WeekdaysModel()
    if (data) {
      this.reload(data)
    }
  }

  reload (data?: any) {
    this.id = data?.id
    this.message = data?.message
    this.channelId = data?.channel?.id
    this.frequency = data?.frequency ?? ReminderFrequency.Weekly
    this.sendingDate = data?.sendingDate
    this.sendingTime = data?.sendingTime
    this.sendingDays = data?.sendingDays
    this.sendingWeekdays.reload(data?.sendingWeekdays)
    this.enabled = data?.enabled

    return this
  }

  dataForUpdate (fields?: Array<string>) {
    const data = {
      message: this.message,
      frequency: this.frequency,
      sendingDate: this.sendingDate,
      sendingTime: this.sendingTime,
      sendingDays: this.sendingDays,
      sendingWeekdays: this.sendingWeekdays,
      enabled: this.enabled,
    }

    removeExcludeKeys(data, fields)

    return data
  }

  dataForCreate () {
    const data = {
      ...{
        channel: this.channelId,
      },
      ...this.dataForUpdate()
    }

    return data
  }
}

import { ApiEntityInterface } from '@/models/interface/api-entity-interface'
import { HasUserInterface } from '@/models/interface/has-user-interface'

export default class RuleModel implements ApiEntityInterface, HasUserInterface {
  public id: string
  public iri: string
  public rule: string
  public isDeleted = false
  public userId: string
  public type = 'regexp_and_condition'

  constructor (data?: any) {
    this.id = data?.id
    this.iri = data ? data['@id'] : null
    this.rule = data?.rule
    this.userId = data?.user?.id
  }

  dataForCreate (): Record<string, unknown> {
    return {
      rule: this.rule,
      type: this.type
    }
  }

  dataForUpdate (): Record<string, unknown> {
    const data = this.dataForCreate()
    data.id = this.iri

    return data
  }
}

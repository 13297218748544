
import { Options, Vue } from 'vue-class-component'
import { getApiClient } from '@/api/APIClient'
import { successToast } from '@/helpers/toastify'
import ReminderModel, { ReminderFrequency } from '@/models/reminder-model'
import CircleCheckbox from '@/components/input/CircleCheckbox.vue'
import { WEEKDAYS } from '@/models/weekdays-model'

@Options({
  components: { CircleCheckbox },
  methods: {
    WEEKDAYS () {
      return WEEKDAYS
    },
    autoHeight: function (event: any) { // KeyboardEvent
      event.target.style.height = '10px'
      event.target.style.height = (event.target.scrollHeight + 30) + 'px'
    }
  },
  props: {
    model: ReminderModel
  },
  mounted () {
    this.$el.querySelectorAll('textarea').forEach((item: HTMLElement) => { item.dispatchEvent(new Event('keyup')) })
    this.sendingDays = this.model?.sendingDays?.join(', ') ?? ''
  }
})
export default class Reminder extends Vue {
  private model: ReminderModel
  private sendingDays: string

  data () {
    return {
      sendingDays: String
    }
  }

  onTimeFieldKeyUp (event: any) {
    if (!['Backspace', 'Delete'].includes(event.key) && event.target.value.length === 2) {
      event.target.value = event.target.value + ':'
    }
  }

  onDateFieldKeyUp (event: any) {
    if (!['Backspace', 'Delete'].includes(event.key) && (event.target.value.length === 2 || event.target.value.length === 5)) {
      event.target.value = event.target.value + '.'
    }
  }

  onDaysFieldKeyUp (event: any) {
    // console.log(event.target.selectionStart)
    // const lastChar = event.target.value[event.target.value.length - 1]
    // const partLeft = event.target.value.substring(0, event.target.selectionStart - 1)
    // const partRight = event.target.value.substring(event.target.selectionStart)
    // console.log(partLeft)
    // switch (event.key) {
    //   case ' ':
    //     event.target.value = partLeft + ', ' + partRight
    //     event.target.selectionStart = (partLeft + ', ').length
    //     break
    //   case ',':
    //     event.target.value = event.target.value + ' '
    //     break
    // }
  }

  enableHandler () {
    if (!this.model.id) {
      return
    }
    getApiClient().updateReminder(this.model, ['enabled']).then((data: ReminderModel|undefined) => {
      if (!data) {
        return
      }
      successToast(this.model.enabled ? 'Activated' : 'Deactivated')
    })
  }

  deleteHandler () {
    this.$emit('delete', this.model.id)
  }

  // updated () {
  //   this.$nextTick(function () {
  //     console.log('Updated')
  //   })
  // }

  private preProcess () {
    this.sendingDays = this.sendingDays.replace(/\s+/g, ' ')
    const arr = this.sendingDays.split(',')
    const newArr = []
    for (const key in arr) {
      if (!isNaN(parseInt(arr[key]))) {
        newArr.push(parseInt(arr[key]))
      }
    }
    this.model.sendingDays = newArr
    this.sendingDays = newArr.join(', ')
  }

  saveHandler () {
    this.preProcess()
    let promise: Promise<ReminderModel|undefined>,
      message: string
    if (this.model.id) {
      promise = getApiClient().updateReminder(this.model)
      message = 'Successfully updated'
    } else {
      promise = getApiClient().createReminder(this.model)
      message = 'Successfully created'
    }
    promise
      .then((data: ReminderModel|undefined) => {
        if (!data) {
          return
        }
        this.model.id = data.id
        successToast(message)
      })
  }
}

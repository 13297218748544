<template>
  <router-view/>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import { Options, Vue } from 'vue-class-component'
import StatisticsLine from '@/components/StatisticsLine'
import store from '@/store'
import { Watch } from 'vue-property-decorator'
import { AppConfig } from '@/config/AppConfig'

@Options({
  components: {
    StatisticsLine,
    Sidebar
  }
})
export default class App extends Vue {
  async created () {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      store.commit('setAccessToken', accessToken)
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (to, from) {
    if (to.name === 'channel') {
      return
    }
    document.title = to.meta.title || AppConfig.appName
  }
}
</script>

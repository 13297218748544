
import { Options, Vue } from 'vue-class-component'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { getApiClient } from '@/api/APIClient'
import { successToast } from '@/helpers/toastify'
import Reminder from '@/components/Reminder.vue'
import { Watch } from 'vue-property-decorator'
import ReminderModel from '@/models/reminder-model'
import ListOfItems from '@/components/ListOfItems.vue'
import { shallowRef } from 'vue'

@Options({
  components: { ListOfItems, Reminder, ConfirmModal },
  props: {
    reminders: Array
  }
})
export default class ChannelView extends Vue {
  private channelId: string
  private reminders: ReminderModel[]
  private confirmDeleteReminderModal: ConfirmModal
  private list: ListOfItems
  private listItemVue = shallowRef(Reminder)

  mounted () {
    this.confirmDeleteReminderModal = (this.$refs.confirmDeleteReminderModal as ConfirmModal)
    this.list = (this.$refs.list as ListOfItems)
  }

  add () {
    this.reminders?.push(new ReminderModel({ channel: { id: '/api/channels/' + this.channelId } }))
  }

  delete (index: number) {
    const reminder = this.reminders[index]
    if (!reminder.id) {
      successToast('Success deleted')
      this.list.delete(index)
      return
    }
    getApiClient().deleteReminder(reminder.id)
      .then((response: any) => {
        if (response.status !== 204) {
          return
        }
        successToast('Success deleted')
        this.list.delete(index)
      })
  }

  getApproveDeleting (index: number) {
    this.confirmDeleteReminderModal.show(index)
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    const channelId = this.$route.params.id.toString()
    if (channelId === this.channelId || !channelId) {
      return
    }
    this.channelId = channelId
  }
}


import { Options, Vue } from 'vue-class-component'
import Hint from '@/components/Hint.vue'
import HintModel from '@/models/hint-model'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { getApiClient } from '@/api/APIClient'
import { Watch } from 'vue-property-decorator'
import ChannelModel from '@/models/channel-model'
import { AppConfig } from '@/config/AppConfig'
import HintsTab from '@/components/HintsTab.vue'
import RemindersTab from '@/components/RemindersTab.vue'
import ReminderModel from '@/models/reminder-model'
import { reactive, ref, shallowReactive, shallowRef } from 'vue'

@Options({
  components: { RemindersTab, HintsTab, Hint, ConfirmModal }
})
export default class ChannelView extends Vue {
  private channelId: string
  private hints: HintModel[]
  private reminders: ReminderModel[]
  private isLoaded = false
  private tab = 'hints'

  data () {
    return {
      channelId: String,
      // channel: store.state.channels[0],
      isLoaded: Boolean,
      hints: this.hints,
      reminders: this.reminders,
      tab: this.tab,
    }
  }

  created () {
    this.channelId = this.$route.params.id.toString()
    this.hints = []
    this.reminders = []
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    const channelId = this.$route.params.id.toString()
    if (channelId === this.channelId || !channelId) {
      return
    }
    this.channelId = channelId

    const channel: ChannelModel = this.$store.getters.getChannel(channelId)
    if (channel) {
      document.title = channel.name + ' - ' + AppConfig.appName
    }
    this.tab = this.$route.query.tab?.toString() ?? 'hints'

    this.channelId = channelId
    this.isLoaded = false
    const promiseHints = getApiClient().getHintsByChannel(this.channelId).then((data: any) => {
      this.hints.splice(0, this.hints.length)
      data.forEach((item: HintModel) => this.hints.push(item))
    })
    const promiseReminders = getApiClient().getRemindersByChannel(this.channelId).then((data: any) => {
      this.reminders.splice(0, this.reminders.length)
      data.forEach((item: ReminderModel) => this.reminders.push(item))
    })

    Promise.all([promiseHints, promiseReminders]).then(() => {
      this.isLoaded = true
    })
  }

  setTabInUrl (tab: string) {
    this.$router.replace({ path: this.$route.path, query: { tab: tab } })
  }
}

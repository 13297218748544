
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    // Sidebar
    // HelloWorld
  }
})
export default class HomeView extends Vue {}

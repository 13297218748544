import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check d-inline-block circle" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["form-check-label justify-content-center align-items-center d-flex", {checked: _ctx.isChecked}]),
      for: 'checkbox'+_ctx.key,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCheck()))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.value), 1)
    ], 10, _hoisted_2)
  ]))
}

import { Options, Vue } from 'vue-class-component'
import HintModel from '@/models/hint-model'
import RuleModel from '@/models/rule-model'
import { hide } from 'slidetoggle'
import { getApiClient } from '@/api/APIClient'
import { errorToast, successToast } from '@/helpers/toastify'
import ReminderModel from '@/models/reminder-model'

@Options({
  methods: {
    autoHeight: function (event: any) { // KeyboardEvent
      event.target.style.height = '10px'
      event.target.style.height = (event.target.scrollHeight + 30) + 'px'
    }
  },
  props: {
    model: HintModel
  },
  mounted () {
    this.$el.querySelectorAll('textarea').forEach((item: HTMLElement) => { item.dispatchEvent(new Event('keyup')) })
  }
})
export default class Hint extends Vue {
  private model: HintModel

  addBlankRule () {
    this.model.rules.push(new RuleModel())
  }

  enableHandler () {
    if (!this.model.id) {
      return
    }
    getApiClient().updateHint(this.model, ['enabled']).then((data: HintModel|undefined) => {
      if (!data) {
        return
      }
      successToast(this.model.enabled ? 'Activated' : 'Deactivated')
    })
  }

  deleteRule (index: number) {
    const el = (this.$refs.rule as any)[index]
    hide(el, {
      miliseconds: 200,
      onAnimationEnd: () =>
        this.model.rules.splice(index, 1)
    })
  }

  deleteRuleById (id: string) {
    const i = this.getIndexRuleById(id)
    if (i === -1) {
      return
    }
    this.deleteRule(i)
  }

  deleteHandler () {
    console.log(this.model.id)
    this.$emit('delete', this.model.id)
  }

  saveHandler () {
    let promise: Promise<HintModel|undefined>,
      message: string
    if (this.model.id) {
      promise = getApiClient().updateHint(this.model)
      message = 'Successfully updated'
    } else {
      promise = getApiClient().createHint(this.model)
      message = 'Successfully created'
    }
    promise
      .then((data: HintModel|undefined) => {
        if (!data) {
          return
        }
        this.model.id = data.id
        successToast(message)
        this.model.rules.forEach((rule: RuleModel, index: number) => {
          if (rule.isDeleted) {
            this.deleteRule(index)
          }
        })
        setTimeout(() => {
          this.model.rules = data.rules
        }, 200)
      })
  }

  deleteRuleHandler (rule: RuleModel) {
    rule.isDeleted = !rule.isDeleted
    // this.$emit('delete-rule', { hint: this.hint.id, rule: id, view: this })
  }

  getIndexRuleById (id: string) {
    for (let i = 0; i < this.model.rules.length; i++) {
      if (id === this.model.rules[i].id) {
        return i
      }
    }

    return -1
  }
}

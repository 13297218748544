import { ActionContext, createStore } from 'vuex'
import HintModel from '@/models/hint-model'
import { getApiClient } from '@/api/APIClient'
import ChannelModel from '@/models/channel-model'
import jwtDecode from 'jwt-decode'

export interface State {
  user: any|undefined,
  channels: ChannelModel[],
  hints: HintModel[],
  isLoadChannel: false,
}

export default createStore<State>({
  state: {
    user: undefined,
    isLoadChannel: false,
    channels: [],
    hints: [],
  },
  getters: {
    getChannels: state => {
      return state.channels
    },
    getChannel: state => (id: string) => {
      return state.channels.find(channel => channel.id === id)
    }
  },
  mutations: {
    setAccessToken: (state, accessToken: string) => {
      if (!accessToken) {
        state.user = undefined
        return
      }

      const tokenDecoded: any = jwtDecode(accessToken)
      if (tokenDecoded) {
        state.user = tokenDecoded.user
      }
    },
  },
  actions: {
    updateChannels: (context: ActionContext<any, any>) => {
      context.state.isLoadChannel = false
      return getApiClient().getChannels().then((res: ChannelModel[]) => {
        context.state.channels = res
        context.state.isLoadChannel = true
        return res
      })
    }
  },
  modules: {
  }
})

import RuleModel from '@/models/rule-model'
import { ApiEntityInterface } from '@/models/interface/api-entity-interface'
import { removeExcludeKeys } from '@/helpers/object-helper'

export default class HintModel implements ApiEntityInterface {
  public id: string
  public message: string
  public lastUsedAt: string
  public delay: number
  public priority: number
  public channelId: string
  public rules: RuleModel[]
  public enabled = true

  constructor (data?: any) {
    this.id = data?.id
    this.message = data?.message
    this.lastUsedAt = data?.lastUsedAt
    this.delay = data?.delay || 300
    this.priority = data?.priority || 1
    this.channelId = data?.channel?.id
    this.rules = []
    this.enabled = data?.enabled || true
    data?.rules.forEach((item: any) => {
      this.rules.push(new RuleModel(item))
    })
  }

  dataForUpdate (fields?: Array<string>) {
    const data = {
      message: this.message,
      delay: this.delay,
      priority: this.priority,
      enabled: this.enabled,
      rules: []
    }

    this.rules.forEach((rule: RuleModel) => {
      if (!rule.isDeleted) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.rules.push(rule.id ? rule.dataForUpdate() : rule.dataForCreate())
      }
    })

    removeExcludeKeys(data, fields)

    return data
  }

  dataForCreate () {
    const data = {
      message: this.message,
      delay: this.delay,
      priority: this.priority,
      channel: this.channelId,
      enabled: this.enabled,
      rules: []
    }
    this.rules.forEach((rule: RuleModel) => {
      if (!rule.isDeleted) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.rules.push(rule.dataForCreate())
      }
    })
    return data
  }
  //
  // updateRules (rulesData: [any]) {
  //   const rules = []
  //   rulesData.forEach((item: any) => {
  //     rules.push(new RuleModel(item))
  //   })
  // }
}

<template>
  <div>
    <div v-if="!isLogin">
      <div class="error-page error-page-fullscreen container">
        <div class="col-md-8 col-12 offset-md-2">
          <div class="text-center">
            <img width="300" class="img-error mb-5" src="../../public/assets/grandad.svg" alt="Access Denied">
            <h1 class="error-title mb-5">Grandad bot</h1>
            <p class="fs-5 text-gray-600">
              To authorize, run the command
              <code class="highlight highlight-pink" ondblclick="this.select()">/grandad login</code>
              in the channel where the bot is added.
            </p>
            <p class="fs-5 text-gray-600">
              You can also click on the  <code class="highlight highlight-pink">Edit</code> button under the hint sent by Дед
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLogin">
      <Sidebar/>
      <div id="main">
        <div class="page-content">
          <section>
<!--            <div class="col-12 col-lg-9">-->
<!--              <StatisticsLine></StatisticsLine>-->
<!--            </div>-->
            <router-view/>
          </section>
        </div>

        <footer>
          <div class="footer clearfix mb-0 text-muted">
            <div class="float-start">
            </div>
            <div class="float-end">
            </div>
          </div>
        </footer>

        <div id="modals"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import { Options, Vue } from 'vue-class-component'
import StatisticsLine from '@/components/StatisticsLine'

@Options({
  components: {
    StatisticsLine,
    Sidebar
  }
})
export default class SidebarLayout extends Vue {
  get isLogin () {
    return this.$store.state.user
  }
}
</script>

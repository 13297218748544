
import { Options, Vue } from 'vue-class-component'
import Hint from '@/components/Hint.vue'
import HintModel from '@/models/hint-model'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import ListOfItems from '@/components/ListOfItems.vue'
import { getApiClient } from '@/api/APIClient'
import { successToast } from '@/helpers/toastify'
import { Watch } from 'vue-property-decorator'
import { shallowRef } from 'vue'

@Options({
  components: { ListOfItems, Hint, ConfirmModal },
  props: {
    hints: Array
  }
})
export default class ChannelView extends Vue {
  private channelId: string
  private hints: HintModel[]
  private confirmDeleteHintModal: ConfirmModal
  private list: ListOfItems
  private listItemVue = shallowRef(Hint)

  mounted () {
    this.confirmDeleteHintModal = (this.$refs.confirmDeleteHintModal as ConfirmModal)
    this.list = (this.$refs.list as ListOfItems)
  }

  add () {
    this.hints?.push(new HintModel({ channel: { id: '/api/channels/' + this.channelId }, rules: [{}] }))
  }

  deleteHint (index: number) {
    const hint = this.hints[index]
    if (!hint.id) {
      successToast('Success deleted')
      this.list.delete(index)
      return
    }
    getApiClient().deleteHint(hint.id)
      .then((response: any) => {
        if (response.status !== 204) {
          return
        }
        successToast('Success deleted')
        this.list.delete(index)
      })
  }

  getApproveDeleting (index: number) {
    this.confirmDeleteHintModal.show(index)
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    const channelId = this.$route.params.id.toString()
    if (channelId === this.channelId || !channelId) {
      return
    }
    this.channelId = channelId
  }
}
